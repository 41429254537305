export const tagStatsTableColumnVisibilityModel = {
	tag:true,
    numContent:true,
    loads:false,
    streams:false,
    pageViews:true,
    averagePageViews:true,
    tagPopularity:false,
    tagUtilization:false,
    navigation:true
}