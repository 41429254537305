export const calculateNextTriggerTime = () => {
    const now = new Date();
    const currentMinutes = now.getUTCMinutes();
    const triggerMinutes = [1, 16, 31, 46];

    let nextTriggerMinutes = triggerMinutes.find(minute => minute > currentMinutes);

    let nextTrigger = new Date();
    if (nextTriggerMinutes === undefined) {
        nextTriggerMinutes = triggerMinutes[0];
        nextTrigger.setUTCHours(now.getUTCHours() + 1);
    }

    nextTrigger.setUTCMinutes(nextTriggerMinutes);
    nextTrigger.setUTCSeconds(0);
    nextTrigger.setUTCMilliseconds(0);

    const timeUntilTrigger = nextTrigger.getTime() - Date.now();

    return Math.max(0, timeUntilTrigger);
};


export const handleRefresh = () => {
    window.location.reload();
};
