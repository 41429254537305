import React, { useState} from "react";
import { Grid, Button, Popover } from "@mui/material"
import { mediaTypeDropdownStyle } from "./MediaTypeDropdownStyle";
import {renderItems } from "./MediaTypeDropdownHelper";
import { mediaTypes } from "../../ComponentHelpers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PropTypes from "prop-types"

export function MediaTypeDropdown({ media, handleMediaChange }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  return (
    <>
      <Button 
        onClick={(event) => {setAnchorEl(event.currentTarget)}} 
        sx={mediaTypeDropdownStyle.labelButton}
        endIcon={<ArrowDropDownIcon />} 
        >
        {media.name}
      </Button>
      <Popover
          id={open ? "mediaType-dropdown" : null}
          open={open}
          anchorEl={anchorEl}
          onClose={()=>setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{sx:mediaTypeDropdownStyle.paperProps}}
      >
                <Grid container direction={"row"} spacing={1} wrap={"nowrap"} sx={mediaTypeDropdownStyle.grid.padding}>
                    <Grid container item sx={mediaTypeDropdownStyle.grid.columnWidth} direction={"column"} wrap={"nowrap"}>
                        {renderItems(mediaTypes, handleMediaChange, ()=>setAnchorEl(null))}
                    </Grid>
                </Grid>
        </Popover>
      </>
  );
}


MediaTypeDropdown.propTypes = {
	media:PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})),
	handleMediaChange: PropTypes.func.isRequired,
}
