import React, { useState, useEffect, useRef } from "react";
import { Button, Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { notificationStyle } from "./NotificationStyle";
import { calculateNextTriggerTime, handleRefresh } from "./NotificationHelper";

export const stagingMessage = "Caution: This is a staging page. Data here is not suitable for reports.";

export const Notification = ({ message, location }) => {
    const [open, setOpen] = useState(false);
    const intervalRef = useRef(null);

    const handleClose = (event, reason) => {
        if (reason !== "clickaway") {
            setOpen(false);
        }
    };

    useEffect(() => {
        const timeUntilNextTrigger = calculateNextTriggerTime();

        const timeout = setTimeout(() => {
            setOpen(true);

            if (!intervalRef.current) {
                intervalRef.current = setInterval(() => {
                    setOpen(true);
                }, 900000); // 15 minutes
            }
        }, timeUntilNextTrigger);

        return () => {
            clearTimeout(timeout);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, []);

    const notificationComponent = (
        <>
            <Button size="small" onClick={handleRefresh} sx={notificationStyle.button}>
                REFRESH
            </Button>
            <IconButton size="small" aria-label="close" onClick={handleClose} sx={notificationStyle.icon}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            message={message}
            action={notificationComponent}
            anchorOrigin={location}
        />
    );
};

Notification.defaultProps = {
    message: "New data may be available—refresh to check for updates.",
    location: { vertical: "bottom", horizontal: "center" }
};

Notification.propTypes = {
    message: PropTypes.string,
    location: PropTypes.shape({
        vertical: PropTypes.oneOf(["top", "bottom"]).isRequired,
        horizontal: PropTypes.oneOf(["left", "center", "right"]).isRequired
    })
};
