import React from "react";
import { Button, Grid } from "@mui/material";
import { mediaTypeDropdownStyle } from "./MediaTypeDropdownStyle";

const handleClick = (value, handleMediaChange, handleClose) => {
    handleMediaChange(value)
    handleClose()
  };

 export const renderItems = (items, handleMediaChange, handleClose) => {
      return(<>{items.map((item, index) => (
        <Grid item>
            <Button variant={"text"} size={"small"} key={index} onClick={() => handleClick(item, handleMediaChange, handleClose)} sx={mediaTypeDropdownStyle.columnButton}>
            {item.name}
            </Button>
        </Grid>
      ))}</>)
  };